<template>
  <div class="credentials">
    <!-- 頁面頂端標題區塊 -->
    <div class="title-block">
      <h1 class="title-block__title">
        {{ $t('credentials.apiKeyList') }}
      </h1>
    </div>
    <!-- 主要內容區塊 -->
    <div class="table-board">
      <div class="table-board__header">
        <button
          class="btn-m btn-default btn-has-icon"
          @click="toggleCreateModal"
        >
          <svg-icon
            icon-class="plus"
            class="icon"
          />
          {{ $t('credentials.createCredential') }}
        </button>
      </div>
      <div class="table-board__body">
        <crud-table
          :headers="tableHeaders"
          :loading="isLoading"
          :data-list.sync="apiKeyList"
          :empty-message="$t('credentials.notYetCreateCredential')"
        >
          <template #action="{ data }">
            <router-link
              :to="{
                params: {
                  key_id: data.id,
                },
                name: 'EditCredential'
              }"
              class="link action-link"
            >
              {{ $t('button.edit') }}
            </router-link>
            <a
              class="link action-link"
              @click="toggleDeleteModal(data.id)"
            >{{ $t('button.delete') }}
            </a>
          </template>
        </crud-table>
      </div>
      <div class="table-board__footer">
        <el-pagination
          v-if="paginationInfo.totalPages > 1"
          :total="paginationInfo.totalItems"
          :page-size="paginationInfo.itemPerPage"
          :current-page="paginationInfo.currentPage + 1"
          class="ss-pagination"
          layout="prev, pager, next"
          @current-change="changePage"
          @prev-click="prevPage"
          @next-click="nextPage"
        />
      </div>
    </div>
    <!-- 建立金鑰彈跳視窗 -->
    <create-key-dialog
      v-if="isCreatingKey"
      :is-processing="isProcessing"
      @closeDialog="toggleCreateModal"
      @createKey="createKey"
    />
    <!-- 刪除金鑰彈跳視窗 -->
    <decide-dialog
      v-if="isDeleteModalShow"
      :title="$t('credentials.deleteAPIKeyWarning')"
      :content="$t('credentials.deleteAPIKeyConfirm')"
      :btn-text="$t('button.confirm')"
      :type="'confirm'"
      @closeDialog="toggleDeleteModal"
      @confirmBtn="deleteKey"
      :is-processing="isProcessing"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/table/CrudTable'
import CreateKeyDialog from '@/pages/credentials/components/CreateKeyDialog'
import DecideDialog from '@/components/dialog/DecideDialog'
import { Message } from 'element-ui'
import { getAPIKeyList, deleteAPIKey, createAPIKey } from '@/API/Credential'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Credentials',
  inject: ['$validator'],
  components: {
    CrudTable,
    CreateKeyDialog,
    DecideDialog
  },
  data () {
    return {
      isProcessing: false,
      isLoading: true,
      isCreatingKey: false,
      isDeleteModalShow: false,
      deletingKeyId: '',
      tableHeaders: [
        {
          text: this.$t('credentials.credentialName'),
          value: 'name',
          sort: true
        },
        {
          text: this.$t('credentials.description'),
          value: 'description',
          ellipsis: true
        },
        {
          text: this.$t('credentials.key'),
          value: 'key',
          ellipsis: true,
          copiable: true,
          copySuccessMessage: this.$t('credentials.keyCopiedMessage'),
          width: '160px'
        },
        {
          text: this.$t('editing.createDate'),
          value: 'createDate',
          sort: true,
          time: 'YYYY-MM-DD'
        },
        {
          text: this.$t('editing.creator'),
          value: 'creatorName'
        },
        {
          text: this.$t('credentials.expiredDate'),
          value: 'expiredDate',
          sort: true,
          time: 'YYYY-MM-DD'
        },
        {
          text: this.$t('editing.action'),
          value: 'action',
          width: '120px'
        }
      ],
      apiKeyList: []
    }
  },
  computed: {
    ...mapState('credentials', ['paginationInfo'])
  },
  created () {
    this.fetchAPIKeyList()
  },
  methods: {
    ...mapMutations('credentials', ['setPaginationInfo']),
    changePage (value) {
      this.setPaginationInfo({
        ...this.paginationInfo,
        currentPage: value - 1
      })
      this.fetchAPIKeyList()
    },
    nextPage () {
      if (this.paginationInfo.currentPage + 1 <= this.paginationInfo.totalPages) {
        this.setPaginationInfo({
          ...this.paginationInfo,
          currentPage: this.paginationInfo.currentPage + 1
        })
        this.fetchAPIKeyList()
      }
    },
    prevPage () {
      if (this.paginationInfo.currentPage - 1 > 0) {
        this.setPaginationInfo({
          ...this.paginationInfo,
          currentPage: this.paginationInfo.currentPage - 1
        })
        this.fetchAPIKeyList()
      }
    },
    toggleCreateModal () {
      this.isCreatingKey = !this.isCreatingKey
    },
    toggleDeleteModal (key_id) {
      this.deletingKeyId = key_id || ''
      this.isDeleteModalShow = !this.isDeleteModalShow
    },
    deleteKey () {
      this.isProcessing = true
      deleteAPIKey(this.deletingKeyId)
        .then(() => {
        // 若刪除的為該頁最後一筆＆＆不是在第一頁時，須將currentPage減少一頁
          if (this.paginationInfo.totalItems % this.paginationInfo.itemPerPage === 1 && this.paginationInfo.currentPage) {
            this.setPaginationInfo({
              ...this.paginationInfo,
              currentPage: this.paginationInfo.currentPage - 1
            })
          }

          // 成功刪除後重新顯示當前頁面的20筆資料、結束process並關閉視窗
          this.fetchAPIKeyList()
          this.isProcessing = false
          this.toggleDeleteModal()

          Message({
            message: this.$t('message.deleteSuccess'),
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        }
        ).catch(() => {
          this.isProcessing = false
          this.toggleDeleteModal()

          Message({
            message: this.$t('message.deleteFailed'),
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
        })
    },
    createKey (inputValue) {
      this.$validator.validateAll()
        .then(result => {
          if (!result) return

          this.isProcessing = true
          const accountId = this.$route.params.account_id
          createAPIKey(accountId, inputValue.description, inputValue.expiredDate, inputValue.name)
            .then((res) => {
              // 新增完後，需讓currentPage維持在最後一頁
              this.setPaginationInfo({
                ...this.paginationInfo,
                currentPage: (this.paginationInfo.totalItems % this.paginationInfo.itemPerPage) ? this.paginationInfo.totalPages - 1 : this.paginationInfo.totalPages
              })

              this.isProcessing = false
              this.toggleCreateModal()
              this.fetchAPIKeyList()

              Message({
                message: this.$t('credentials.createAPIKeySuccess'),
                type: 'success',
                duration: 3 * 1000,
                showClose: true
              })
            })
            .catch(() => {
              this.isProcessing = false
              Message({
                message: this.$t('credentials.createAPIKeyFailed'),
                type: 'error',
                duration: 3 * 1000,
                showClose: true
              })
            })
        })
    },
    fetchAPIKeyList () {
      this.isLoading = true
      const accountId = this.$route.params.account_id
      const page = this.paginationInfo.currentPage
      const size = this.paginationInfo.itemPerPage

      getAPIKeyList(accountId, page, size)
        .then((res) => {
        // 設定分頁資訊
          this.setPaginationInfo(res.pagination)
          // 將物件格式再轉回陣列格式
          const data = Object.values(res).filter((item, index, array) => index < array.length - 3)
          this.apiKeyList = data
          // 結束loading狀態
          this.isLoading = false
        })
        .catch(() => {
        // 結束loading狀態
          this.isLoading = false

          Message({
            message: this.$t('credentials.fetchAPIKeyFailed'),
            type: 'error',
            duration: 3 * 1000,
            showClose: true
          })
        })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'EditCredential') {
      this.setPaginationInfo({
        currentPage: 0,
        totalPages: 0,
        totalItems: 0,
        itemPerPage: 20
      })
    }
    next()
  }
}
</script>
