<template>
  <div class="full-create-dialog">
    <h2>{{ $t('credentials.createCredential') }}</h2>
    <div
      class="full-create-dialog__body"
    >
      <a
        class="close-dialog"
        @click="closeDialog"
      >
        <svg-icon icon-class="close" />
      </a>
      <form class="create-key-form">
        <!-- 輸入名稱區塊 -->
        <div class="input-wrapper">
          <label>{{ $t('credentials.credentialName') }}</label>
          <input-verify
            v-validate="'required'"
            v-model="inputValue.name"
            :placeholder="$t('editing.pleaseEnterName')"
            :name="'name'"
          />
        </div>
        <!-- 輸入描述區塊 -->
        <div class="input-wrapper">
          <label>{{ $t('credentials.description') }}</label>
          <input-verify
            v-model="inputValue.description"
            :name="'description'"
            :placeholder="$t('editing.pleaseEnterContent')"
          />
        </div>
        <!-- 輸入到期日期區塊 -->
        <div class="input-wrapper">
          <label>{{ $t('credentials.expiredDate') }}</label>
          <el-date-picker
            v-model="inputValue.expiredDate"
            :type="'date'"
            :placeholder="$t('credentials.selectDate')"
            :format="'yyyy-MM-dd'"
            :picker-options="datePicker.options"
          />
        </div>
      </form>
    </div>

    <div
      class="full-create-dialog__footer"
    >
      <button
        :disabled="isProcessing"
        class="btn btn-outline dialog-btn"
        @click="closeDialog"
      >
        {{ $t('button.cancel') }}
      </button>
      <button
        :disabled="isProcessing"
        class="btn btn-default dialog-btn dialog-create"
        @click="createKey"
      >
        <svg-icon
          v-if="isProcessing"
          icon-class="spinner"
        />
        {{ $t('button.submit') }}
      </button>
    </div>
  </div>
</template>

<script>
import InputVerify from '@/components/InputVerify'

export default {
  inject: ['$validator'],
  name: 'CreateKeyDialog',
  components: {
    InputVerify
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: {
        name: '',
        description: '',
        expiredDate: ''
      },
      datePicker: {
        options: {
          disabledDate: (time) => time.getTime() < new Date().getTime()
        }
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    createKey () {
      this.$emit('createKey', this.inputValue)
    }
  }
}
</script>
<style lang="scss" scoped>
.full-create-dialog {
  &__body {
    padding: 19px 16px;
    position: relative;

    .close-dialog {
      color: #fff;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: -40px;

      &:hover {
        opacity: 0.8;
      }
    }

    .create-key-form {
      background: rgba(72, 84, 84, 0.9);
      border-radius: 12px;
      padding: 16px;
      position: relative;
      width: 652px;

      .input-wrapper {
        display: flex;
        flex-direction: column;

        ::v-deep .input-verify-text {
          color: $theme-text-color;
          width: 60%;
        }

        ::v-deep .el-date-editor {
          .el-input__inner {
            background-color: transparent;
            border-bottom: 1px solid white;
            border-radius: unset;
            font-size: 16px;
            padding: 0 !important;
          }
        }
      }
    }
  }
}
</style>
