import request from '@/utils/publicRequest'

/**
 * 取得api金鑰的清單
 * @param {String} accountId
 * @param {Number} page
 * @param {Number} size
 */
export function getAPIKeyList (accountId, page, size) {
  return request({
    url: '/apiKey/list',
    method: 'GET',
    params: { accountId, page, size }
  })
}

/**
 * 新增api金鑰
 * @param {String} accountId
 * @param {String} description
 * @param {String} expiredDate
 * @param {String} name
 */
export function createAPIKey (accountId, description, expiredDate, name) {
  return request({
    url: '/apiKey',
    method: 'POST',
    data: { accountId, description, expiredDate, name }
  })
}

/**
 * 取得指定的api金鑰
 * @param {String} keyId
 */
export function getAPIKey (keyId) {
  return request({
    url: `/apiKey/${keyId}`,
    method: 'GET'
  })
}

/**
 * 修改api金鑰
 * @param {String} keyId
 * @param {Object} keyInfo
 */
export function editAPIKey (keyId, keyInfo) {
  return request({
    url: `/apiKey/${keyId}`,
    method: 'PUT',
    data: keyInfo
  })
}

/**
 * 重新產生一組金鑰代碼
 * @param {String} keyId
 */
export function recreateAPIKey (keyId) {
  return request({
    url: `/apiKey/${keyId}/key`,
    method: 'PUT'
  })
}

/**
 * 刪除api金鑰
 * @param {String} keyId
 */
export function deleteAPIKey (keyId) {
  return request({
    url: `/apiKey/${keyId}`,
    method: 'DELETE'
  })
}
