var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"credentials"},[_c('div',{staticClass:"title-block"},[_c('h1',{staticClass:"title-block__title"},[_vm._v(" "+_vm._s(_vm.$t('credentials.apiKeyList'))+" ")])]),_c('div',{staticClass:"table-board"},[_c('div',{staticClass:"table-board__header"},[_c('button',{staticClass:"btn-m btn-default btn-has-icon",on:{"click":_vm.toggleCreateModal}},[_c('svg-icon',{staticClass:"icon",attrs:{"icon-class":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('credentials.createCredential'))+" ")],1)]),_c('div',{staticClass:"table-board__body"},[_c('crud-table',{attrs:{"headers":_vm.tableHeaders,"loading":_vm.isLoading,"data-list":_vm.apiKeyList,"empty-message":_vm.$t('credentials.notYetCreateCredential')},on:{"update:dataList":function($event){_vm.apiKeyList=$event},"update:data-list":function($event){_vm.apiKeyList=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var data = ref.data;
return [_c('router-link',{staticClass:"link action-link",attrs:{"to":{
              params: {
                key_id: data.id,
              },
              name: 'EditCredential'
            }}},[_vm._v(" "+_vm._s(_vm.$t('button.edit'))+" ")]),_c('a',{staticClass:"link action-link",on:{"click":function($event){return _vm.toggleDeleteModal(data.id)}}},[_vm._v(_vm._s(_vm.$t('button.delete'))+" ")])]}}])})],1),_c('div',{staticClass:"table-board__footer"},[(_vm.paginationInfo.totalPages > 1)?_c('el-pagination',{staticClass:"ss-pagination",attrs:{"total":_vm.paginationInfo.totalItems,"page-size":_vm.paginationInfo.itemPerPage,"current-page":_vm.paginationInfo.currentPage + 1,"layout":"prev, pager, next"},on:{"current-change":_vm.changePage,"prev-click":_vm.prevPage,"next-click":_vm.nextPage}}):_vm._e()],1)]),(_vm.isCreatingKey)?_c('create-key-dialog',{attrs:{"is-processing":_vm.isProcessing},on:{"closeDialog":_vm.toggleCreateModal,"createKey":_vm.createKey}}):_vm._e(),(_vm.isDeleteModalShow)?_c('decide-dialog',{attrs:{"title":_vm.$t('credentials.deleteAPIKeyWarning'),"content":_vm.$t('credentials.deleteAPIKeyConfirm'),"btn-text":_vm.$t('button.confirm'),"type":'confirm',"is-processing":_vm.isProcessing},on:{"closeDialog":_vm.toggleDeleteModal,"confirmBtn":_vm.deleteKey}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }